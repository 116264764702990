@import '../../../sass/variables';

// .post-article
.post-article {
    padding: 0px 30px;
    margin-bottom: 10px;

    h1 {
        font-family: $headerBoldFontStack;
    }

    .category-post {
        font-size: $bodyIncreasedSize;
        text-transform: uppercase;
        line-height: $headerIncreasedLineHeight;
    }

    .image-padding {
        padding: 50px 15px 50px 15px;
    }
}

@media only screen and (max-width: 991px) {
    .post-article {
        padding: 0;

        .col-sm-12 {
            padding: 0;
        }

        h1,
        .h4,
        .category-post {
            padding: 0 30px;
        }

        .mobile-featured {
            padding: 30px 0;
            margin: 0 auto;
            width: 100%;
        }
    }
}

// .post-event
.post-event {
    & > .module {
        & > .container {
            & > .col-sm-12 {
                .event-context {
                    margin-bottom: 15px;
                    color: $solBlue;
                    figure {
                        display: inline-block;
                        width: 23px;
                        height: 23px;
                        margin-right: 7.5px;
                        vertical-align: text-bottom;
                    }
                }
                .event-detail {
                    color: $charcoal;
                    > div {
                        margin-bottom: 15px;
                        figure {
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                            vertical-align: middle;
                        }
                        span {
                            text-transform: capitalize;
                        }
                    }
                    > div:last-of-type {
                        margin-bottom: 0;
                    }
                }
                #main-image {
                    position: relative;
                    margin-bottom: 30px;
                    figcaption {
                        position: absolute;
                        top: 20px;
                        padding: 2.5px 7.5px 2.5px 15px;
                        color: $charcoal;
                        background-color: $heroCTAYellow;
                    }
                }
                #info-panel {
                    margin-bottom: 30px;
                    padding-left: 100px;
                    & > .row {
                        margin-right: -5px;
                        margin-left: -5px;
                        & > .col-xs-12 {
                            padding-right: 5px;
                            padding-left: 5px;
                            .event-date {
                                margin-bottom: 18px;
                            }
                            .event-duration {
                                text-transform: capitalize;
                            }
                            .event-deadline {
                                margin-bottom: 21px;
                            }
                        }
                        & > .col-xs-12:last-of-type {
                            text-align: right;
                        }
                    }
                }
                #address-details {
                    padding-bottom: 30px;
                }
                #accessibility-list {
                    ul.no-disc {
                        margin-bottom: 0;
                        padding-left: 0;
                        list-style-type: none;
                        li {
                            margin-bottom: 15px;
                            figure {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                margin-right: 5px;
                                vertical-align: middle;
                            }
                        }
                        & > li:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
                #main-content {
                    padding-left: 100px;
                    border-left: 1px solid $charcoal;
                    & > section {
                        padding-bottom: 30px;
                    }
                    #speaker-grid {
                        .row {
                            margin-right: -7.5px;
                            margin-left: -7.5px;
                        }
                        .speaker-block {
                            padding-right: 7.5px;
                            padding-left: 7.5px;
                            margin-bottom: 15px;
                            img {
                                margin-bottom: 15px;
                            }
                            figcaption {
                                p {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    #marketo-form {
                        form {
                            margin-bottom: 0;
                        }
                        label {
                            font-size: 14px;
                            font-weight: normal;
                        }
                        .form-control {
                            background-color: $white;
                            border: 1px solid $proGreyLightAlt;
                            color: $proTextGray;
                            margin-bottom: 25px;
                        }
                        // react-select
                        .react-select__control {
                            margin: -12px -10px;
                            background-color: $white;
                            border: none;
                            .react-select__value-container {
                                padding: 7px 55px 7px 10px;
                                min-height: 49px;
                                .react-select__placeholder {
                                    color: $proTextGray;
                                }
                                .react-select__multi-value {
                                    background-color: $breadCrumbBackgroundColor;
                                    border: 1px solid $assetHighlightsGrey;
                                    .react-select__multi-value__label {
                                        padding-right: 6px;
                                        font-size: 100%;
                                        color: $proTextGray;
                                        white-space: normal;
                                    }
                                    .react-select__multi-value__remove {
                                        border-left: 1px solid $assetHighlightsGrey;
                                        svg {
                                            height: 16px;
                                            width: 16px;
                                        }
                                        &:hover {
                                            background-color: $assetHighlightsGrey;
                                            color: inherit;
                                        }
                                    }
                                }
                                #react-select-2-input {
                                    position: absolute;
                                }
                            }
                            .react-select__indicators {
                                display: none;
                            }
                            &.react-select__control--is-focused {
                                box-shadow: none;
                                outline-color: #3b99fc !important;
                                outline-offset: -2px !important;
                                outline-style: auto !important;
                                outline-width: 5px !important;
                            }
                        }
                        .react-select__menu {
                            margin: 2px 0;
                            border-radius: 0;
                            left: 0;
                        }

                        // error styling
                        .form-group {
                            .error:not(button) {
                                width: calc(100% - 50px);
                            }
                            .form-error {
                                position: absolute;
                                top: 30px;
                                right: 35px;
                                padding: 0;
                                margin: 0;
                                width: auto;
                                min-height: 48px;
                                &::before {
                                    display: block;
                                }
                            }
                            .error-block + .select-arrow {
                                right: 50px;
                            }
                        }
                        .select-wrapper .form-error {
                            top: 0;
                        }

                        #disclaimer-text {
                            margin-bottom: 25px;
                        }
                        #button-submit {
                            margin-right: 0;
                        }
                    }
                    & > section:last-of-type {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}

// sweet-alert
.event-sweet-alert-class-needed {
    .sweet-alert {
        width: 60%;
        max-height: 100%;
        overflow: scroll;
        left: 0;
        margin: 0 20%;
        padding: 30px;
        text-align: left;
        border: 1px solid $solBlue;
        border-radius: 0;
        > .sa-icon,
        > h2 {
            display: none !important;
        }
        p {
            color: $charcoal;
            line-height: 24px;
        }
        h2 {
            margin-top: 0px;
            margin-bottom: 15px;
            color: $charcoal;
            font-size: 28px;
            line-height: 32px;
            text-align: left;
            font-weight: $headerReducedWeight;
        }
        #submit-dialog {
            .submission-status {
                margin-bottom: 15px;
                color: $solBlue;
                figure {
                    display: inline-block;
                    width: 23px;
                    height: 23px;
                    margin-right: 7.5px;
                    vertical-align: text-bottom;
                }
                & + p {
                    margin-bottom: 30px;
                }
            }
            & > .row {
                margin-right: -7.5px;
                margin-left: -7.5px;
                & > .col-xs-12:first-of-type {
                    float: right;
                }
                & > .col-xs-12 {
                    padding-right: 7.5px;
                    padding-left: 7.5px;
                    .event-context {
                        margin-bottom: 15px;
                        color: $solBlue;
                        figure {
                            display: inline-block;
                            width: 23px;
                            height: 23px;
                            margin-right: 7.5px;
                            vertical-align: text-bottom;
                        }
                    }
                    .contact-us {
                        margin: 15px 0;
                        & + p {
                            color: $solBlue;
                            margin-bottom: 0;
                        }
                    }
                }
                & > .col-xs-12:last-of-type {
                    float: left;
                }
            }
            &.error-dialog {
                .submission-status {
                    margin-bottom: 30px;
                }
            }
        }
        .sa-button-container {
            text-align: center;
            button {
                min-width: 120px;
                padding: 8px 15px;
                font-size: 16px;
                font-family: 'SST W20 Medium', Verdana, sans-serif;
                background-color: transparent !important;
                border: 1px solid $solBlue;
                color: $solBlue;
                line-height: 1.33333;
                border-radius: 0;
                transition: all 0.25s ease;
                &:hover,
                &:focus {
                    background-color: $solBlue !important;
                    color: $white;
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .post-event {
        & > .module {
            & > .container {
                & > .col-sm-12 {
                    #info-panel {
                        padding-left: 15px;
                    }
                    #main-content {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .post-event {
        & > .module {
            & > .container {
                padding: 0;
                width: auto;
                & > .col-sm-12 {
                    #main-content {
                        #marketo-form {
                            // error styling
                            .form-group {
                                .error:not(button) {
                                    width: 100%;
                                }
                                .form-error {
                                    &::before {
                                        display: none;
                                    }
                                }
                                .error-block + .select-arrow {
                                    right: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .post-event {
        & > .module {
            & > .container {
                & > .col-sm-12 {
                    #main-image {
                        margin-right: -15px;
                        margin-left: -15px;
                        > img {
                            margin: 0 auto;
                        }
                    }
                    #info-panel {
                        padding-left: 0;
                        .event-date {
                            margin-bottom: 0;
                        }
                        .event-duration {
                            margin-bottom: 30px;
                        }
                        .event-deadline {
                            margin-bottom: 0;
                        }
                        .event-detail {
                            margin-top: 30px;
                            > div {
                                margin-bottom: 0;
                            }
                        }
                    }
                    #main-content {
                        border-left: none;
                        #speaker-grid {
                            .speaker-block {
                                img {
                                    margin: 0 auto;
                                }
                            }
                            .speaker-block:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    // sweet-alert
    .event-sweet-alert-class-needed {
        .sweet-alert {
            width: 90%;
            margin: 0 5%;
            padding: 15px;
            max-height: 100%;
            overflow: scroll;
            #submit-dialog {
                .submission-status {
                    margin: 15px 0;
                    & + p {
                        margin-bottom: 15px;
                    }
                }
                & > .row {
                    margin-right: -7.5px;
                    margin-left: -7.5px;
                    & > .col-xs-12 {
                        padding-right: 7.5px;
                        padding-left: 7.5px;
                        .event-image {
                            margin: 0 -15px;
                            > img {
                                margin: 0 auto;
                            }
                        }
                        h2 {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}
