@import '../../../sass/variables';
@import '../../../sass/animation';

.Select-control {
    border-radius: 3px;
    border-color: $proDarkGrey;
}

.Select,
.Select div,
.Select input,
.Select span {
    color: $proTextGrayAlt;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: $proTextGrayAlt;
}

.Select.is-focused:not(.is-open) > .Select-control {
    border-color: $proDarkGrey;
}

.Select-arrow-zone {
    &:before {
        font-family: 'sonyngp';
        content: '\7a';
        color: $proTextGrayAlt;
        font-size: 17px;
        position: absolute;
        right: 6px;
        top: 3px;
    }
    .is-open &::before {
        font-family: 'sonyngp';
        content: '\77';
        top: 5px;
    }
    .Select-arrow {
        display: none;
    }
}
